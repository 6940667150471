<template lang="pug">
  .crm-input(:class="classObject")
    base-label(:required="requiredField" :label="label" :v2="v2")
    .crm-input__wrapper
      img(v-if="iconLink" :src="require('@/assets/img/' + iconLink)").icon
      span.crm-input__before-icon(v-if="$slots.before")
        slot(name="before")
      input(
        ref="input"
        v-model="inputValue"
        :type="type"
        :placeholder="placeholder"
        :value="value"
        :min="min"
        :max="max"
        :readonly="readonly"
        @click="$emit('click', $event)"
        @focus="$emit('focus', $event)"
        @blur="$emit('blur', $event)"
        @keyup="$emit('keyup', $event)"
      )
      span.crm-input__after-icon(v-if="$slots.after")
        slot(name="after")
      span.crm-input__clearable
        transition(name="fade")
          app-button(v-if="clearable && value" icon transparent @click.native="$emit('input', null)").unselectable
            <i class="material-icons">clear</i>
    span.error-message {{ errorMessages }}
</template>

<script>
import appButton from '@/components/global/actions/BaseButton.vue'

export default {
  name: 'crmInput',

  props: {
    label: String,
    value: {
      required: true
    },
    type: {type: String, default: 'text'},
    placeholder: String,
    iconLink: String,
    min: Number,
    max: Number,
    readonly: Boolean,
    text: Boolean,
    error: Boolean,
    errorMessages: String,
    requiredField: Boolean,
    clearable: Boolean,
    v2: Boolean
  },

  computed: {
    classObject: function () {
      return {
        'crm-input_icon': this.iconLink,
        'filled': this.inputValue,
        'crm-input_text': this.text,
        'crm-input_before': this.$slots['before'],
        'crm-input_after': this.$slots['after'],
        'crm-input--clearable': this.clearable,
        'crm-input--error': this.error,
        'crm-input--v2': this.v2
      }
    },
    inputValue: {
      get: function () {
        return this.value
      },
      set: function (value) {
        this.$emit('input', value)
      }
    }
  },

  methods: {
    focus() {
      this.$refs.input.focus();
    }
  },

  components: {
    appButton,
    baseLabel: () => import('@/components/global/actions/BaseControlLabel.vue')
  }
}
</script>

<style lang="scss">
.crm-input {
  position: relative;
  display: block;
  padding-bottom: 10px;


  input {
    padding: 10px 12px;
    width: 100%;
    border: solid 1px $border-color;
    border-radius: 4px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 6px 0 rgb(0 0 0 / 5%);
    color: #232428;
    letter-spacing: normal;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    font-size: 12px;
    font-family: $font-roboto;
    transition: 0.2s;

    caret-color: #4E7FED;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      margin: 0;

      -webkit-appearance: none;
    }

    &[type=number] {
      -moz-appearance: textfield;
    }

    &::placeholder {
      color: #BBC4CA;
      letter-spacing: normal;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      font-size: 12px;
      font-family: $font-roboto;
      line-height: normal;
    }

    &:focus {
      outline: #4E7FED;
      border-color: #4E7FED;
    }
  }


  &_ {
    &text {
      padding-bottom: 0;
      & input {
        padding: 0;
        border: unset;
        background-color: unset;
        box-shadow: unset;
        font-family: $font-roboto;
      }
    }
    &icon {
      input {
        padding-left: 32px !important;
      }
    }
    &before input {
      padding-left: 37px !important;
    }
    &after input {
      padding-right: 37px !important;
    }
  }

  &__ {
    &wrapper {
      position: relative;
    }
    &before-icon {
      position: absolute;
      top: 5px;
      right: auto;
      bottom: auto;
      left: 5px;
    }
    &after-icon {
      position: absolute;
      top: 5px;
      right: 5px;
      bottom: auto;
      left: auto;
    }
    &clearable {
      position: absolute;
      top: 5px;
      right: 5px;
      bottom: auto;
      left: auto;
    }
  }

  &-- {
    &error {
      input {
        border-color: $error-color !important;
        color: $error-color !important;

        &:focus {
          outline: $error-color !important;
          border-color: $error-color !important;
        }
      }
    }

    &clearable {
      input {
        padding-right: 37px !important;
      }
    }
  }

  .icon {
    position: absolute;
    top: 50%;
    right: auto;
    bottom: auto;
    left: 10px;
    transform: translateY(-50%);
    pointer-events: none;
  }


  &--v2 {
    input {
      font-size: 12px;
      font-family: $font;
    }
  }
}

</style>
